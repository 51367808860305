<template>
  <div class="home">
    <h1 class="welcome-message">Welcome to my Website!</h1>
    <img class="profile-pic" src="../assets/profilepic.png">
    <h2 class="section-header">About me</h2>
    <p class="section-content">My name is Irvin Mendoza, I'm a graduate in computer science and an art hobbyist on the side</p>
    <p class="section-content">As you can probably tell, this website is a work in progress, but I intend to update it in the future so that I can share my work and projects.  For now, feel free to check out my twitter below where I post most of my artwork</p>

    <a class="twitter-timeline" data-width="320" data-height="400" data-theme="light" href="https://twitter.com/Gerotan18?ref_src=twsrc%5Etfw">Tweets by Gerotan18</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </div>
</template>

<script>
  export default {
    name: 'Home',
  }
</script>

<style>

  .home {
    align-self: center;
    max-width: 30rem;
  }

  .welcome-message {
    margin-bottom: 1rem;
  }

  .profile-pic {
    width: 18rem;
    height: 20rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .section-content {
    margin: 1rem 2rem 1rem 2rem;
  }

  .twitter-timeline {
    margin-left: auto;
    margin-right: auto;
  }

</style>
