<template>
  <div class="gallery">
    <h1>This is the gallery page</h1>
  </div>
</template>

<script>
  export default {
    name: 'Gallery',
  }
</script>

<style>
</style>
